body{
    background-color: #0d0d0d;
}

h1 {
    font-size: 6rem;
    font-family: Rubik Mono One, monospace;
        font-weight: 400;
        font-style: normal;
        color: rgb(12 12 12);

}

h2 {
    font-size: 3rem;
    font-family: Rubik Mono One, monospace;
    font-weight: 400;
    font-style: normal;
    color: rgb(137, 137, 137);
}

h3 {
    font-size: 1rem;
    font-family: Rubik Mono One, monospace;
        font-weight: 100;
        font-style: normal;
        color: rgb(108, 108, 108);

}

.news-h1 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}
.editor{
    background-color: antiquewhite;
    border: 1px solid rgb(204, 204, 204);
    min-height: 200px;
    padding: 10px;
    border-color: #252817;
    border-width: thick;
    border-radius: 9px;
}

#news {
    padding-left: 22vw !important;
    padding-right: 10vw;
}

#releases{
    padding-right: 1vw;
}

.main-editor-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ff000073;
    background-size: cover;
    position: relative;
}

.main-editor-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../public/assets/Images/blackpattern.jpg) no-repeat center center;
    background-size: cover;
    opacity: 0.7;
    z-index: -1;
}

.banner-image{
    height: 66vw;
    margin-right: 0vw;
    margin-top: 19vh;
    border: none;
    mix-blend-mode: luminosity;
    }

.banner-container {
    position: relative;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    background-size: cover;
    background-position: center;
}

.banner-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 1; /* Adjust the opacity to make the video more subtle */
}

.banner-content {
    position: relative;
    z-index: 1;
    text-align: center;
}

.logo {
    height: 37vh;
    position: relative;
    top: 19vh;
    right: 10vw;
    border: none;
    padding: 47px;
    background-color: black;
    background-color: #000000de;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.vds-audio-layout.light, .light .vds-audio-layout{
--default-brand: rgb(239 0 0) !important;
--default-color: rgb(208 208 208)   !important;
--default-bg: rgb(0 0 0) !important;
--default-controls-color: rgb(255 255 255) !important;
--default-border: 1px solid rgb(100 100 100 /0.2)   !important;
--default-slider-track-bg: rgb(50 50 50 / 32%) !important;
--default-slider-progress-bg: rgb(10 10 10 / 0.2) !important;
}

.social-media-links{
    display: flex;
    flex-direction: column;
}

.svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 3em;
    overflow: visible;
    vertical-align: -0.125em;
    color: #f0f0f0;
}

/* News content Front Page */
.css-480o17-MuiGrid-root {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-top: 0px;
    width: calc(100% + 32px);
    align-items: flex-start;
    align-content: center;
    width: 100% !important;
}

@media screen and (max-width: 768px) {
        .logo {
            height: 21vh;
            position: absolute;
            bottom: 15vh;
            right: 28vw;
        }    
        .banner-image {
                height: 79vw;
                margin-right: 20vw;
                margin-top: 97px;       
        } 
        #root > div > div{
            width: -webkit-fill-available;
        } 
        h2{
            font-size: 1rem;
            margin-bottom: 14px;
        } 
        p{
            font-size: 15px;
        } 
        textarea {
            font-size: 15px !important;
        }
        .editor{
            font-size: 15px !important;
        }
        input{
            font-size: 15px !important;
        }
        .css-480o17-MuiGrid-root {
            box-sizing: border-box;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            flex-direction: column;
            margin-top: 0px;
            align-items: center;
            align-content: center;
            width: 100% !important;
        }
        #news {
            padding-left: 2vw !important;
            padding-right: 2vw;
            min-width: 100%;
        }
        .css-1g5qvo6 {
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: space-evenly;
            -ms-flex-pack: space-evenly;
            -webkit-justify-content: space-evenly;
            margin-top: 49px;
            margin-bottom: 43px;
            justify-content: center;
            flex-wrap: wrap;
        }
    }

@media screen and (max-width: 425px) {
        .banner-container{
            position: relative !important;
            display: flex;
            align-items: center;
            flex-direction: column-reverse;
        }
        .logo {
            height: 20vh;
            padding: 11px;
            background-color: #000000c7;
            border-radius: 26px;
            right: 1vw;
            margin-bottom: 59vh;
            z-index: 99999 !important;
            margin-top: 8px;
            position: relative !important;
        }
        .img{
            padding: 0;
            border-radius: 0;
            border: 0;
        }
        .banner-image {
                display: none !important;
        
        .main-editor-div h1 {
        font-size: 2rem;
        text-align: center;
        }  

}
}
    


