body {
background-image: url('https://res.cloudinary.com/djunroohl/image/upload/v1730754650/mixerboard_tcirv1.jpg');
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
}

img {
    padding: 10vw;
    border-radius: 30px;
    border: 8px solid #00000040;
}

.vipBackground {
    background-color: darkslategray;
}

.postTitle{
    color: seashell;
    display: flex;
    justify-content: center;
    margin-right: 10vw;
}

@keyframes textGlow {
    0% {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ff00ff, 0 0 20px #ff00ff, 0 0 25px #ff00ff, 0 0 30px #ff00ff, 0 0 35px #ff00ff;
    }
    50% {
      text-shadow: 0 0 10px #fff, 0 0 20px #ff00ff, 0 0 30px #ff00ff, 0 0 40px #ff00ff, 0 0 50px #ff00ff, 0 0 60px #ff00ff, 0 0 70px #ff00ff;
    }
    100% {
      text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #ff00ff, 0 0 20px #ff00ff, 0 0 25px #ff00ff, 0 0 30px #ff00ff, 0 0 35px #ff00ff;
    }
  }
  
  .vipBackground h1 {
    animation: textGlow 2s infinite;
    color: #fff;
    text-align: center;
    margin-top: 20px;
  }